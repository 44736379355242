import { Row, Col, Divider } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { HiOutlineMail } from 'react-icons/hi';
import { BsTelephone } from 'react-icons/bs';
import logo from 'assets/images/logo-big-rentals-black-white.png';
import facebook from 'assets/images/facebook.png';
import twitter from 'assets/images/twitter.png';
import linkedin from 'assets/images/linkedin.png';
import instagram from 'assets/images/instagram.png';
import { screenStyles } from 'core/constants/styles';

function Footer() {
  const currentYear: number = new Date().getFullYear();
  const location = useLocation();
  const hasTrailers = location.pathname.endsWith('/trailer-rentals');

  return (
    <footer className={`bg-darkGreen text-white py-30 ${!hasTrailers ? 'mt-40 lg:mt-60' : ''}`}>
      <div className="max-w-mxWidth mx-auto">
        <div className={` text-16 text-lemonGrass ${screenStyles.mContain}`}>
          <Row className="my-20">
            <Col xs={24} sm={24} md={9} className="my-20">
              <div className="mx-20">
                <img className="App-logo" src={logo} alt="" />
                <p className="my-16">Big equipment rentals made easy.</p>
                <div className="flex">
                  <img src={facebook} className="mr-10" alt="" />
                  <img src={twitter} className="mr-10" alt="" />
                  <img src={linkedin} className="mr-10" alt="" />
                  <img src={instagram} className="mr-10" alt="" />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={8} md={5} className="my-20">
              <div className="mx-20">
                <p className="text-white text-18 font-bold">Rental Customers</p>
                <p><NavLink to="/trailer-rentals" className="text-lemonGrass">Trailer Rentals</NavLink></p>
                <p><NavLink to="/trailer-rentals" className="text-lemonGrass">Equipment Rentals</NavLink></p>
                <p><NavLink to="/trailer-rentals/locations" className="text-lemonGrass">Service Areas</NavLink></p>
                <p className="hidden"><NavLink to="/rental-resources" className="text-lemonGrass">Rental Resources</NavLink></p>
                <p className="hidden"><a href="https://2sijn2ulrxo.typeform.com/to/QnhjmCg0" className="text-lemonGrass" target="_blank" rel="noreferrer">List Your Trailer</a></p>
              </div>
            </Col>
            <Col xs={24} sm={8} md={5} className="my-20">
              <div className="mx-20">
                <p className="text-white text-18 font-bold">Rental Companies</p>
                <p><a href="https://software.bigrentals.com" className="text-lemonGrass" target="_blank" rel="noreferrer">Rental Software</a></p>
                <p><a href="https://software.bigrentals.com" className="text-lemonGrass" target="_blank" rel="noreferrer">List Equipment</a></p>
                <p className="hidden"><NavLink to="/rental-resources" className="text-lemonGrass">Company Resources</NavLink></p>
                <p className="hidden"><NavLink to="/trailer-rentals" className="text-lemonGrass">Case Studies</NavLink></p>
              </div>
            </Col>
            <Col xs={24} sm={8} md={5} className="my-20">
              <div className="mx-20">
                <p><NavLink to="/contact-us" className="text-white text-18 font-bold">Contact Us</NavLink></p>
                <p className="flex items-center">
                  <HiOutlineMail className="mr-10" />
                  support@bigrentals.com
                </p>
                <p className="flex items-center">
                  <BsTelephone className="mr-10" />
                  {' '}
                  (650) 646-RENT
                </p>
                <p className="flex items-center">
                  <BsTelephone className="mr-10" />
                  {' '}
                  (650) 646-7368
                </p>
                {/* <p className="flex items-center">
                  <MdOutlineLocationOn className="mr-10" />
                  {' '}
                  Los Angeles, CA
                </p> */}
              </div>
            </Col>
            <Divider style={{ margin: '20px 0' }} />

          </Row>
          <div className="block justify-between sm:flex font-medium">
            <p className="mb-20">
              ©
              {' '}
              {currentYear}
              {' '}
              Big Rentals Inc
            </p>

            <div className="block sm:flex">
              <p className="mr-10 my-16"><NavLink to="/privacy-policy" className="text-lemonGrass">Privacy Policy</NavLink></p>
              <p className="mr-10 my-16"><NavLink to="/terms-service" className="text-lemonGrass">Terms of Service</NavLink></p>
            </div>

          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
