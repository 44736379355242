import React from 'react';
import type { IRouteItemProp } from './interfaces/base';

const HomePage = React.lazy(() => import('pages/HomePage'));
const MapPage = React.lazy(() => import('pages/Map'));
const TrailersCalifornia = React.lazy(() => import('pages/ServiceArea/state/california'));
const TrailersNevada = React.lazy(() => import('pages/ServiceArea/state/nevada'));
const TrailersMaine = React.lazy(() => import('pages/ServiceArea/state/maine'));
const TrailersMassachusetts = React.lazy(() => import('pages/ServiceArea/state/massachusetts'));
const TrailersNewHampshire = React.lazy(() => import('pages/ServiceArea/state/newHampshire'));
const TrailersIdaho = React.lazy(() => import('pages/ServiceArea/state/idaho'));
const TrailersTexas = React.lazy(() => import('pages/ServiceArea/state/texas'));
const Trailers = React.lazy(() => import('pages/LandingPage/trailers'));
const AllProducts = React.lazy(() => import('pages/AllProducts'));
const FlatbedTrailers = React.lazy(() => import('pages/LandingPage/trailers/flatbed'));
const TrailersNevadaCity = React.lazy(() => import('pages/trailers/nevada/city'));
const EnclosedTrailers = React.lazy(() => import('pages/LandingPage/trailers/enclosed'));
const EquipmentTrailers = React.lazy(() => import('pages/LandingPage/trailers/equipment'));
const RollOffDumpTrailers = React.lazy(() => import('pages/LandingPage/trailers/rollOfDump'));
const GooseneckTrailers = React.lazy(() => import('pages/LandingPage/trailers/gooseneck'));
const UtilityTrailers = React.lazy(() => import('pages/LandingPage/trailers/utility'));
const TiltTrailers = React.lazy(() => import('pages/LandingPage/trailers/tilt'));
const DumpTrailers = React.lazy(() => import('pages/LandingPage/trailers/dump'));
const Results = React.lazy(() => import('pages/ProductDetail/Results'));
const ProductDetail = React.lazy(() => import('pages/ProductDetail'));
const ServiceArea = React.lazy(() => import('pages/ServiceArea'));
const Blog = React.lazy(() => import('pages/Blog'));
const BlogArticle = React.lazy(() => import('pages/Blog/BlogArticle'));
const BlogContent = React.lazy(() => import('pages/BlogContent'));
const IndustrySolution = React.lazy(() => import('pages/IndustrySolution'));
const Checkout = React.lazy(() => import('pages/CheckOut'));
const ShoppingCart = React.lazy(() => import('pages/ShoppingCart'));
const Promotion = React.lazy(() => import('pages/Promotion'));
const ConfirmPage = React.lazy(() => import('pages/CheckOut/confirmPage'));
const PrivacyPolicy = React.lazy(() => import('pages/privacyPage'));
const TermsPolicy = React.lazy(() => import('pages/termPage'));
const ContactUS = React.lazy(() => import('pages/contactUS'));

export const routeItems: IRouteItemProp[] = [
  {
    path: '/',
    element: <HomePage />,
    id: 'home',
    sitemap: {
      changefreq: 'daily',
      priority: '1.0',
    },
  },
  {
    path: '/trailer-rentals/map',
    element: <MapPage />,
    id: 'map',
  },
  {
    path: '/trailer-rentals/locations',
    element: <ServiceArea />,
    id: 'servicearea',
  },
  {
    path: '/trailer-rentals/california/:city?',
    element: <TrailersCalifornia />,
    id: 'trailers-california',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
      path: '/trailer-rentals/california',
    },
  },
  {
    path: '/trailer-rentals/nevada/:city?',
    element: <TrailersNevada/>,
    id: 'trailers-nevada',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
      path: '/trailer-rentals/nevada',
    },
  },
  {
    path: '/trailer-rentals/maine/:city?',
    element: <TrailersMaine/>,
    id: 'trailers-maine',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
      path: '/trailer-rentals/maine',
    },
  },
  {
    path: '/trailer-rentals/massachusetts/:city?',
    element: <TrailersMassachusetts/>,
    id: 'trailers-massachusetts',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
      path: '/trailer-rentals/massachusetts',
    },
  },
  {
    path: '/trailer-rentals/new-hampshire/:city?',
    element: <TrailersNewHampshire/>,
    id: 'trailers-new-hampshire',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
      path: '/trailer-rentals/new-hampshire',
    },
  },
  {
    path: '/trailer-rentals/idaho/:city?',
    element: <TrailersIdaho/>,
    id: 'trailers-idaho',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
      path: '/trailer-rentals/idaho',
    },
  },
  {
    path: '/trailer-rentals/texas/:city?',
    element: <TrailersTexas/>,
    id: 'trailers-texas',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
      path: '/trailer-rentals/texas',
    },
  },
  {
    path: '/trailer-rentals',
    element: <Trailers />,
    id: 'trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/trailer-rentals/results',
    element: <AllProducts />,
    id: 'product',
    sitemap: {
      changefreq: 'daily',
      priority: '0.8',
    },
  },
  {
    path: '/trailer-rentals/flatbed-trailers',
    element: <FlatbedTrailers />,
    id: 'flatbed-trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/trailer-rentals/enclosed-trailers',
    element: <EnclosedTrailers />,
    id: 'enclosed-trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/trailer-rentals/equipment-trailers',
    element: <EquipmentTrailers />,
    id: 'equipment-trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/trailer-rentals/roll-off-dump-trailers',
    element: <RollOffDumpTrailers />,
    id: 'roll-off-dump-trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/trailer-rentals/gooseneck-trailers',
    element: <GooseneckTrailers />,
    id: 'gooseneck-trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/trailer-rentals/utility-trailers',
    element: <UtilityTrailers />,
    id: 'utility-trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/trailer-rentals/tilt-trailers',
    element: <TiltTrailers />,
    id: 'tilt-trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/trailer-rentals/dump-trailers',
    element: <DumpTrailers />,
    id: 'dump-trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/:category/results/:assetId',
    element: <Results />,
    id: 'equip',
  },
  {
    path: '/trailer-rentals/:subcategory/:assetId',
    element: <ProductDetail />,
    id: 'proddetail',
  },
  {
    'path': '/trailer-rentals/nevada/:city',
    'element': <TrailersNevadaCity />,
    'id': 'trailers-nevada-city',
  },
  {
    path: '/rental-resources',
    element: <Blog />,
    id: 'rental-resources',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/rental-resources/earthmoving',
    element: <BlogContent />,
    id: 'rental-resources-content',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/rental-resources/brake',
    element: <BlogArticle />,
    id: 'rental-resources-article',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/rental-resources/maintain',
    element: <BlogArticle />,
    id: 'rental-resources-article',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/rental-resources/strapping',
    element: <BlogArticle />,
    id: 'rental-resources-article',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/industry-solution',
    element: <IndustrySolution />,
    id: 'industry-solution',
  },
  {
    path: '/checkout',
    element: <Checkout />,
    id: 'checkout',
  },
  {
    path: '/shoppingcart',
    element: <ShoppingCart />,
    id: 'shoppingcart',
  },
  {
    path: '/promotion',
    element: <Promotion />,
    id: 'promotion',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/confirmation',
    element: <ConfirmPage />,
    id: 'confirmation',
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
    id: 'privacy-policy',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/terms-service',
    element: <TermsPolicy />,
    id: 'terms-service',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/contact-us',
    element: <ContactUS />,
    id: 'contact-us',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
];
