import { Modal } from 'antd';
import { TiLocationOutline } from 'react-icons/ti';
import { useState, useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { locationApi } from 'core/api/locations.api';
import { SettingContext } from 'core/context/SettingContext';
import { ICityAPIProp } from 'core/interfaces/base';
import {DEFAULT_MAP_ZOOM} from 'core/constants/constant';

interface ISearchProperties{
    bgcolor?: string;
    className?: string;
}

function LocationSearch({ bgcolor, className }: ISearchProperties) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { location, setLocation, setLocationZoom } = useContext(SettingContext);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeSelectedOption = (e: any) => {
    setLocation(e);
    setLocationZoom(DEFAULT_MAP_ZOOM);
  };

  const fetchCities = async (inputCity: string) => {
    const { data } = await locationApi.getCities(inputCity);
    const cities: any = [];

    if (data && data.status === 'OK') {
      data?.cities?.forEach((city: ICityAPIProp) => {
        const item = {
          label: city.name,
          value: city.cityId,
          obj: city,
        };

        cities.push(item);
      });
    }

    return cities;
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: any[]) => void,
  ) => {
    fetchCities(inputValue).then((res: any[]) => {
      callback(res);
    });
  };

  const yellowBtn = 'px-30 py-15 rounded-full bg-supernova text-darkGreen font-bold border hover:text-white hover:bg-yellowBrown';

  return (
    <div className={className}>
      <div className="flex items-center text-16 rounded-12 h-40 location-search">
        <TiLocationOutline className="cursor-pointer font-bold text-20 mr-10 text-black" onClick={showModal} />
        <AsyncSelect
          className="select-location w-full"
          cacheOptions
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={onChangeSelectedOption}
          loadOptions={loadOptions}
          value={location}
          getOptionLabel={(props: any) => {
            const { label } = props;
            return label
              ? ((
                <div className="flex items-center gap-2">
                  <div className="mr-10 location-icon flex items-center justify-center w-40 h-40 rounded-lg bg-[#ededed]">
                    <TiLocationOutline className="font-bold text-20" />
                  </div>

                  <span>{label}</span>
                </div>
                          ) as unknown as string)
              : ((
                <span style={{ color: '#717171' }}>
                  Set Location
                </span>
                          ) as unknown as string);
          }}
          placeholder="City, Zip "
        />
      </div>
      <Modal
        className="pinpoint-modal"
        title={[
          <p className="text-20 font-bold mt-10 text-dune ">Let’s pinpoint your jobsite</p>,
          <p className="text-14 mb-5 text-boulder ">
            Please provide the jobsite location to help us determine accurate
            <br />
            {' '}
            rates and inventory in that area.
          </p>,
          <>
            <div className="flex items-center rounded-12 text-boulder bg-desertStorm py-15 px-20 text-14 mb-30">
              <TiLocationOutline className="font-bold text-20" onClick={showModal} />
              <AsyncSelect
                className="select-location w-full modal-location"
                cacheOptions
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                onChange={onChangeSelectedOption}
                loadOptions={loadOptions}
                value={location}
                getOptionLabel={(props: any) => {
                  const { label } = props;
                  return label
                    ? ((
                      <div className="flex items-center gap-2">
                        <div className="mr-10 location-icon flex items-center justify-center w-40 h-40 rounded-lg bg-[#ededed]">
                          <TiLocationOutline className="font-bold text-20" />
                        </div>

                        <span>{label}</span>
                      </div>
                  ) as unknown as string)
                    : ((
                      <span style={{ color: '#717171' }}>
                        Set Location
                      </span>
                  ) as unknown as string);
                }}
                placeholder="City, Zip "
              />
            </div>
            <div className="flex flex-row-reverse">
              <button type="submit" className={yellowBtn} onClick={handleOk}>Set Location</button>
            </div>
          </>,
        ]}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      />
    </div>
  );
}

export default LocationSearch;
