import { useState, useEffect } from 'react';

const Backdrop = ({ active }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (active) {
      setIsVisible(true); // Set visible immediately to apply fade-in
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300); // Wait for fade-out to complete before hiding
      return () => clearTimeout(timer);
    }
  }, [active]);

  return (
    <div
      className={`w-full h-full fixed left-0 top-0 z-[100] bg-[rgba(0,0,0,0.5)] transition-opacity duration-300 ${
        active ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
      style={{ display: isVisible ? 'block' : 'none' }} // Ensure it hides after fade-out completes
    ></div>
  );
};

export default Backdrop;
