import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { hideChatWidgetHelper } from './hide-chat-widget-helper';
import { URI_RESULTS} from 'core/constants/constant';

export default function HideChatWidget(): null {
  const location = useLocation();

  // Determine if the Chat widget should be hidden based on the current pathname
  const isPageToHide = location.pathname === '/trailer-rentals/map';
  const isResultPageToHide = location.pathname === URI_RESULTS;

  // Function to check if the screen width is less than a specific threshold
  const isScreenWidthToHide = window.innerWidth < 1460;

  const helper = hideChatWidgetHelper();

  useEffect(() => {
    const handleResize = () => {
      if (isPageToHide || (isResultPageToHide && isScreenWidthToHide)) {
        helper.hideChatWidget();
      } else {
        helper.showChatWidget();
      }
    };

    // Initial check
    handleResize();

    // Add event listener to handle screen resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isPageToHide, isScreenWidthToHide, isResultPageToHide]);

  return null;
}
