import dayjs, { Dayjs } from "dayjs";
import { getEnableTimes } from 'core/constants/globalfunc';

export const weekDays = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
] as const;

export type Day = typeof weekDays[number];

export interface HourInfo<T = 'AUTOMATED' | 'STAFF'> {
  pickupType: T;
  week: Day;
  closed: boolean;
  hours: { [key in 'open' | 'close']: string; }[]
}

export interface HoursMap {
  STAFF: {
    [key in Day]: HourInfo<'STAFF'>;
  };
  AUTOMATED: {
    [key in Day]: HourInfo<'AUTOMATED'>;
  }
}

export const isDateDisabledBasedOnHours = (date: Date | Dayjs, hoursMap?: HoursMap) => {
  const day = dayjs(date).get('day');

  const dayHoursMap = hoursMap?.STAFF?.[weekDays[day]];
  let disable = false;
  if(!dayHoursMap)
  	return disable;
  disable =  dayHoursMap?.closed || !dayHoursMap?.hours || !dayHoursMap?.hours?.length;

  //check if today date has any open time, else disable today
  if(!disable && dayjs().format("MM/DD/YYYY") === dayjs(date).format("MM/DD/YYYY")){
	const newPickupTimes: any = getEnableTimes(date, hoursMap);
	disable = !newPickupTimes || newPickupTimes.length === 0;
  }
  return disable;
};
