import { useContext } from 'react';
import { Modal } from 'antd';
import { TiLocationOutline } from 'react-icons/ti';
import AsyncSelect from 'react-select/async';
import { locationApi } from '../core/api/locations.api';
import { SettingContext } from 'core/context/SettingContext';
import { ICityAPIProp } from 'core/interfaces/base';

type argType = {
	header?: string;
	body?: string;
	isLocModalOpen: boolean;
	setIsLocModalOpen: (isLocModalOpen: boolean) => void;
};

function LocationSearchModal(args: argType) {
  const { location,setLocation } = useContext(SettingContext);

  const {
    header = 'Where would you like to rent?',
    body = 'Please provide the location where you would like to rent so we can find you the most accurate listings for you',
    isLocModalOpen, setIsLocModalOpen,
  } = args;

  const handleOk = () => {
    setIsLocModalOpen(false);
  };

  const handleCancel = () => {
    setIsLocModalOpen(false);
  };

  const getOptionValue = (option:any) => (option.label ? option.label : option.name);

  const yellowBtn = 'px-30 py-15 rounded-full bg-supernova text-darkGreen font-bold border hover:text-white hover:bg-yellowBrown';

  const loadOptions = (
    inputValue:string,
      callback: (options: any[]) => void,
  ) => {
    fetchCities(inputValue).then((res) => {
      callback(res);
    });
  };

  const fetchCities = async (inputCity: string) => {
    const { data } = await locationApi.getCities(inputCity);
    const cities:any = [];

    if (data && data.status === 'OK') {
      data?.cities?.forEach((city: ICityAPIProp) => {
        const item = {
          label: city?.name,
          value: city?.cityId,
          obj: city,
        };

        cities.push(item);
      });
    }

    return cities;
  };

  return (
    <div>
      <Modal
        className="pinpoint-modal"
        title={[
          <p className="text-20 font-bold mt-10 text-dune ">{header}</p>,
          <p className="text-14 mb-5 text-boulder ">{body}</p>,
        ]}
        open={isLocModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="flex items-center rounded-12 text-boulder py-15 px-20 text-14 mb-30">
          <AsyncSelect
            className="bg-softpeach max-w-250 w-250"
            cacheOptions
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            onChange={(e) => setLocation(e)}
            loadOptions={loadOptions}
            value={location}
            getOptionLabel={(props: any) => (
              <div className="flex items-center gap-2">
                <TiLocationOutline className="font-bold text-20" />
                <span>{getOptionValue(props)}</span>
              </div>
                  ) as unknown as string}
            placeholder="City, Zip"
          />
        </div>
        <div className="flex flex-row-reverse">
          <button type="submit" className={yellowBtn} onClick={handleOk}>Set Location</button>
        </div>
      </Modal>
    </div>
  );
}

export default LocationSearchModal;
