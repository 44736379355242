/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { chooseIndustryProp } from '../interfaces/base';

export interface IMenuProp {
  label: string;
  key: string;
  value?: string;
  url?: string;
}

export const menu_languages: IMenuProp[] = [
  {
    label: 'ENG',
    key: '1',
    value: '1',
  },
];
export const menu_trailers: IMenuProp[] = [
  {
    label: 'All Trailers',
    key: '1',
    value: '',
    url: '/trailer-rentals',
  },
  {
    label: 'Flatbed Trailers',
    key: '2',
    value: '11',
    url: '/trailer-rentals/results?category=flatbed',
  },
  {
    label: 'Enclosed Trailers',
    key: '3',
    value: '10',
    url: '/trailer-rentals/results?category=enclosed',
  },
  {
    label: 'Equipment Trailers',
    key: '4',
    value: '21',
    url: '/trailer-rentals/results?category=equipment',
  },
  {
    label: 'Utility Trailers',
    key: '5',
    value: '16',
    url: '/trailer-rentals/results?category=utility',
  },
  {
    label: 'Dump Trailers',
    key: '6',
    value: '15',
    url: '/trailer-rentals/results?category=dump',
  },
  {
    label: 'Tilt Trailers',
    key: '7',
    value: '20',
    url: '/trailer-rentals/results?category=tilt',
  },
  {
    label: 'Roll-Off Dump Trailers',
    key: '8',
    value: '18',
    url: '/trailer-rentals/results?category=roll-off',
  },
  {
    label: 'Gooseneck Trailers',
    key: '9',
    value: '19',
    url: '/trailer-rentals/results?category=gooseneck',
  },
  {
    label: 'Deckover Trailers',
    key: '10',
    value: '17',
    url: '/trailer-rentals/results?category=deckover',
  },
];
export const menu_equipments: IMenuProp[] = [
  {
    label: 'All Equipment',
    key: '0',
    url: '/trailer-rentals',
  },
  {
    label: 'Compact Track Loaders',
    key: '1',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Wheeled Skid Steers',
    key: '2',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Mini Excavators',
    key: '3',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Scissor Lifts',
    key: '4',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Articulating Boom Lifts',
    key: '5',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Straight Boom Lifts',
    key: '6',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Towable Boom Lifts',
    key: '7',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Telehandlers',
    key: '8',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Roll Off Dumpsters',
    key: '9',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Trash Bins',
    key: '10',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Warehouse Forklifts',
    key: '11',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Heavy Duty Forklifts',
    key: '12',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Rough Terrain Forklifts',
    key: '13',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Pallet Jacks',
    key: '14',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Walkie Stackers',
    key: '15',
    url: '/trailer-rentals/results',
  },
];
export const menu_profile: IMenuProp[] = [
  {
    label: 'Login',
    key: '1',
  },
  {
    label: 'Sign up',
    key: '2',
  },
  {
    label: 'Checkout',
    key: '3',
    url: '/checkout',
  },
  {
    label: 'How we works',
    key: '4',
  },
  {
    label: 'Trailer FAQs',
    key: '5',
  },
];

export const choose_industries: chooseIndustryProp[] = [
  {
    title: 'Industry',
    url: '',
  },
];
