import { useState, useContext, useEffect } from 'react';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { RiGlobalLine } from 'react-icons/ri';
import { BiHelpCircle, BiPhoneCall, BiMenu, BiChevronDown } from 'react-icons/bi';
import { IoLocationOutline } from 'react-icons/io5';
import { CgProfile } from 'react-icons/cg';
import { MdOutlineClose } from 'react-icons/md';
import { Dropdown, Space, Divider, Collapse } from 'antd';
import { menu_languages, menu_trailers, menu_profile, menu_equipments } from 'core/constants/menu';
import { SettingContext } from 'core/context/SettingContext';
import type { IMenuProp } from 'core/constants/menu';
import LocationSearchModal from 'components/LocationSearchModal';
import LocationSearch from 'components/AllProducts/locationSearch';
import '../App.css';
import logo from 'assets/images/logo-big-rentals-black-white.png';
import { btnStyles, screenStyles } from '../core/constants/styles';

const { Panel } = Collapse;

function Header(props: any) {
  const { location, showTopHeader, setActiveGradient, activeGradient } = useContext(SettingContext);
  const [isLocModalOpen, setIsLocModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const [background, setBackground] = useState('');
  const [showMMenu, setShowMMenu] = useState(false);

  const listenScrollEvent = (e: any) => {
    const fullUrl = window.location.href;

    // Create a URL object
    const url = new URL(fullUrl);
    
    // Get the pathname (everything after the domain)
    const pathname = url.pathname;
    
    // Split the pathname by '/'
    const pathSections = pathname.split('/');
    
    // The section you're interested in is the first one after the domain
    const section = pathSections[1];

    let convertPos = 15;

    if(section === ''){
      convertPos = 185;
    }
    else if (section === 'trailers' && pathSections[2] === 'results') {
      convertPos = 1;
    } 
    else if (section === 'trailers' && !pathSections[2]) {
      convertPos = 76;
    } else if (section === 'trailers' && pathSections[2] !== 'results') {
      convertPos = 118;
    }

    if (window.scrollY > convertPos) {
      setBackground('bg-darkGreen animate-show');
    } else {
      setBackground('bg-tranparent');
    }
  };

  const listenResizeWdith = (e: any) => {
    setShowMMenu(false);
    setActiveGradient(false);
  };

  const toggleMobileMenu = () => {
    if (!showMMenu) {
      setShowMMenu(true);
      setActiveGradient(true);
    } else {
      setShowMMenu(false);
      setActiveGradient(false);
    }
  };

  const onMenuClick = (e: any) => {
    const { key, url } = e;
    setActiveGradient(false);
    if (url) {
      navigate(url);
    }

    navigate('/product');
  };



  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    window.addEventListener('resize', listenResizeWdith);
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
      window.removeEventListener('resize', listenResizeWdith);
      setActiveGradient(false);
    };
  }, []);

  const headerStyles = {
    mobileMenu: 'fixed left-0 top-80 grid w-full bg-white p-24 z-[101] font-medium text-16',
    header: `flex justify-between items-center font-medium ${screenStyles.mContain}`,
    mMenuItem: 'text-black py-10 border-b-1 border-softpeach ',
  };

  return (
    <div className="text-white">
      <div className={`${showMMenu ? 'bg-darkGreen' : background} fixed top-0 left-0 w-full z-900 bg-headerGradient`}>
        <div className={`${screenStyles.desktop} my-20 max-w-mxWidth mx-auto `}>
          {showTopHeader && (
            <>
              <div className={`${headerStyles.header} mt-20 mb-10`}>
                <div className="flex items-center text-14">
                  <RiGlobalLine className="mr-10" />

                  <Dropdown
                    menu={{
                      items: menu_languages,
                      className: '!p-15 !rounded-[10px]',
                      onClick: onMenuClick,
                    }}
                  >
                    <a href="/" onClick={(e) => e.preventDefault()}>
                      <Space className="text-white">
                        ENG
                        <BiChevronDown />
                      </Space>
                    </a>
                  </Dropdown>

                </div>
                <div className="flex items-center text-14">
                  <NavLink to="/contact-us" className="text-white mr-10">Help</NavLink>
                  <BiHelpCircle className="mr-20" />
                  <NavLink
                    to=""
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLocModalOpen(true);
                    }}
                    className="text-white mr-10"
                  >
                    {location?.label ? location?.label : 'Location'}
                  </NavLink>
                  <IoLocationOutline className="mr-20" />
                  <NavLink to="/contact-us" className="text-white mr-10">Contact Us</NavLink>
                  <BiPhoneCall className="mr-0" />
                </div>
              </div>
              {isLocModalOpen && (
                <LocationSearchModal
                  isLocModalOpen={isLocModalOpen}
                  setIsLocModalOpen={setIsLocModalOpen}
                />
              )}
              <Divider style={{margin: "0px"}}/>
            </>
          )}
          <div className={`${headerStyles.header} mt-20`}>
            <div className="display-menu-sapce flex items-center text-white text-16">
              <img src={logo} className="App-logo" onClick={() => navigate('/')} style={{ 'pointerEvents': 'all' }} alt="" />
              <Dropdown
                menu={{
                  items: menu_trailers.map((menu: IMenuProp, index: number) => ({
                    key: index.toString(),
                    label: (
                      <Link
                        to={menu?.url ?? '/trailer-rentals'}
                        key={menu.key}
                        className="block w-full h-full px-[12px] py-[5px]"
                        onClick={() => {
                          setShowMMenu(false);
                          setActiveGradient(false);
                        }}
                      >
                        {menu.label}
                      </Link>
                    ),
                    className: '!bg-white !p-0',
                  })),
                  className: "!p-15 !rounded-[10px]",
                }}
              >
                <a href="/" onClick={(e) => e.preventDefault()}>
                  <Space className="text-white ml-20">
                    Trailer Rentals
                    <BiChevronDown />
                  </Space>
                </a>
              </Dropdown>
              <Dropdown
                menu={{
                  items: menu_equipments.map((menu: IMenuProp, index: number) => ({
                    key: index,
                    label: (
                      <Link
                        to={menu?.url ?? '/trailer-rentals/results'}
                        key={menu.key}
                        className="block w-full h-full px-[12px] py-[5px]"
                        onClick={() => setShowMMenu(false)}
                      >
                        {menu.label}
                      </Link>
                    ),
                    className: '!bg-white !p-0',
                  })),
                  className: "!p-15 !rounded-[10px]",
                }}
              >
                <a href="/" onClick={(e) => e.preventDefault()}>
                  <Space className="text-white ml-20">
                    Equipment Rentals
                    <BiChevronDown />
                  </Space>
                </a>
              </Dropdown>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 0,
                      label: (
                        <a target="_blank" rel="noreferrer" href="https://software.bigrentals.com" 
                        onClick={() => {
                          setShowMMenu(false);
                          setActiveGradient(false);
                        }}>
			                <Space className="block w-full h-full px-[12px] py-[5px]">
			                  Rental Software
			                </Space>
			            </a>
                      ),
                      className: '!bg-white !p-0',
                    },
                    {
                      key: 1,
                      label: (
                       <a target="_blank" rel="noreferrer" href="https://software.bigrentals.com" 
                       onClick={() => {
                        setShowMMenu(false);
                        setActiveGradient(false);
                      }}>
			                <Space className="block w-full h-full px-[12px] py-[5px]">
			                  List Equipment
			                </Space>
			            </a>
                      ),
                      className: '!bg-white !p-0',
                    }
                  ],
                  className: "!p-15 !rounded-[10px]",
                }}
              >
                <a href="/" onClick={(e) => e.preventDefault()}>
                  <Space className="text-white ml-20">
                    Rental Companies
                    <BiChevronDown />
                  </Space>
                </a>
              </Dropdown>
              {
                /*
                <NavLink to="/resources" className="text-white ml-20">Rental Resources</NavLink>
                <NavLink to="/about" className="text-white ml-20">About</NavLink>
                
              <a target="_blank" rel="noreferrer" href="https://software.bigrentals.com">
                <Space className="text-white ml-20">
                  Rental Software
                </Space>
              </a>
              <a href="/rental-resources">
                <Space className="text-white ml-20">
                  Rental Resources
                </Space>
              </a>
              */
              }
            </div>
            <div className="flex items-center">
              <a
                href="https://software.bigrentals.com"
                className={btnStyles.listTrailer}
                target="_blank"
                rel="noreferrer"
              >
                List Equipment
              </a>
              {
                /*
                  <Dropdown overlay={(
                    <Menu
                      onClick={onMenuClick}
                      items={menu_profile}
                      style={{ padding: '15px', borderRadius: '10px' }}
                    />
                  )}
                  >
                    <a href="/" onClick={(e) => e.preventDefault()}>
                      <Space className="text-20 text-white ml-20">
                        <CgProfile />
                        <BiChevronDown />
                      </Space>
                    </a>
                  </Dropdown>
                */
              }

            </div>
          </div>
        </div>

        <div className={`${screenStyles.mobile} w-screen py-25 px-20`}>
          <div className="flex justify-between w-full text-white">
            <BiMenu size={25} onClick={toggleMobileMenu} className={showMMenu ? 'hidden' : ''} />
            <MdOutlineClose size={25} onClick={toggleMobileMenu} className={showMMenu ? '' : 'hidden'} />
            <img className="App-logo" src={logo} alt="" onClick={() => navigate('/')} style={{ 'pointerEvents': 'all' }} />
            <span />
            <Dropdown
              menu={{
                items: menu_profile,
                onClick: onMenuClick,
                style: { padding: '15px', borderRadius: '10px' }
              }}
              className="hidden"
            >
              <a href="/" onClick={(e) => e.preventDefault()}>
                <Space className="text-20 text-white ml-20">
                  <CgProfile />
                  <BiChevronDown />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
      {showMMenu && (
        <div className={headerStyles.mobileMenu}>
          <Collapse expandIconPosition="end" bordered={false}>
            <Panel header="Trailer Rentals" key="1" className="text-16">
              <div className="grid">
                {
                  menu_trailers.map((menu: IMenuProp, index: number) => (
                    <NavLink
                      to={menu?.url ?? '/product'}
                      className="py-10 text-black border-b-1 border-softpeach"
                      key={index}
                      onClick={() => {
                        setShowMMenu(false);
                        setActiveGradient(false);
                      }}
                    >
                      {menu.label}
                    </NavLink>
                  ))
                }
              </div>
            </Panel>
          </Collapse>
          <div className="border-softpeach  border-t-2">
            <Collapse expandIconPosition="end" bordered={false}>
              <Panel header="Equipment Rentals " key="1" className="text-16">
                <div className="grid">
                  {
                  menu_equipments.map((menu: IMenuProp, index: number) => (
                    <NavLink
                      to={menu?.url ?? '/trailer-rentals/results'}
                      className="py-10 text-black border-b-1 border-softpeach"
                      key={index}
                      onClick={() => {
                        setShowMMenu(false);
                        setActiveGradient(false);
                      }}
                    >
                      {menu.label}
                    </NavLink>
                  ))
                }
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="border-softpeach  border-t-2">
            <Collapse expandIconPosition="end" bordered={false}>
              <Panel header="Rental Companies" key="1" className="text-16">
                <div className="grid">
                  <a target="_blank" rel="noreferrer" href="https://software.bigrentals.com" 
                      onClick={() => {
                        setShowMMenu(false);
                        setActiveGradient(false);
                      }}>
		                <Space className="py-10 text-black border-b-1 border-softpeach">
		                  Rental Software
		                </Space>
		          </a>
		          <a target="_blank" rel="noreferrer" href="https://software.bigrentals.com" 
                      onClick={() => {
                        setShowMMenu(false);
                        setActiveGradient(false);
                      }}>
		                <Space className="py-10 text-black border-b-1 border-softpeach">
		                  List Equipment
		                </Space>
		          </a>
                </div>
              </Panel>
            </Collapse>
          </div>
          {/*<a className="text-black py-10 border-b-1 border-softpeach  border-t-1" target="_blank" rel="noreferrer" href="https://software.bigrentals.com">
            Rental Software
          </a>
          <a className="text-black py-10 border-b-1 border-softpeach  border-t-1" href="/rental-resources">
            Rental Resources
          </a>
          */}
          <a className="text-black py-10 border-b-1 border-softpeach  border-t-1" target="_blank" rel="noreferrer" href="https://software.bigrentals.com">
            List Equipment
          </a>
          <button className={`w-full h-40 my-10 hidden ${btnStyles.default}`} onClick={() => {
                          setShowMMenu(false);
                          setActiveGradient(false);
                        }}>Sign Up</button>
          <button className={`w-full h-40 my-10 hidden ${btnStyles.default}`} onClick={() => {
                          setShowMMenu(false);
                          setActiveGradient(false);
                        }}>Login</button>

          <LocationSearch className="my-10" />
          <a className="text-black py-10 border-b-1 border-softpeach  border-t-1" href="/contact-us">
            Contact Us
          </a>
          <Divider />
          <div className="mt-10">
            {/* <RiGlobalLine style={{ marginRight: 10, color: 'black' }} size={18} /> */}
            <Collapse expandIconPosition="end" bordered={false}>
              <Panel
                header={(
                  <div className="flex items-center">
                    <RiGlobalLine className="text-20 font-bold" />
                    <span className="ml-10">ENG</span>
                  </div>
                )}
                key="1"
              >
                <div className="grid">
                  <NavLink to="/" className="p-10">ENG</NavLink>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      )}
    </div>

  );
}

export default Header;
