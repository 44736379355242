export const btnStyles = {
  default: 'bg-transparent rounded-full text-center font-bold cursor-pointer hover:bg-supernova text-black hover:text-black border-1 border-supernova',
  active: 'bg-supernova rounded-full text-center font-bold cursor-pointer hover:bg-transparent hover:text-black border-1 border-supernova',
  outline: 'bg-transparent rounded-full text-center text-white font-bold cursor-pointer hover:bg-supernova hover:text-black border-1 border-supernova',
  listTrailer: 'w-full md:w-150 h-50 rounded-full bg-transparent text-dune text-white border border-boulder flex items-center justify-center hover:text-white',
  addCart: 'w-full md:w-120 h-50 rounded-full bg-supernova text-darkGreen font-bold border hover:text-white hover:bg-yellowBrown',
  calcBtn: 'w-full h-50 rounded-full bg-supernova text-darkGreen font-bold border hover:text-white hover:bg-yellowBrown',
  yellow: 'rounded-full bg-supernova text-darkGreen font-bold border hover:text-white hover:bg-yellowBrown',
  disabled: 'bg-gray-300 rounded-full text-center font-bold text-gray-700 cursor-not-allowed border-1 border-gray-300',
};

export const screenStyles = {
  desktop: ' hidden lg:block ',
  mobile: ' block lg:hidden ',
  mdDesktop: 'hidden md:block ',
  mdMobile: 'block md:hidden ',
  smDesktop: 'hidden sm:block ',
  smMobile: 'block sm:hidden overflow-x-hidden',
  mContain: ' mx-25 sm:mx-40 md:mx-60 lg:mx-80 ',
};

export const dividerStyles = {
  horizontal: ' w-full h-[1px] my-10 md:my-20 bg-softpeach ',
  vertical: ' w-[1px] bg-softpeach ',
  defaultH: ' w-full h-[1px] bg-softpeach ',
  defaultV: ' w-[1px] bg-softpeach ',
};

export const titleStyles = {
  default: `${screenStyles.mContain} text-40 font-bold text-white text-center lg:text-left leading-[120%]`,
};

export const headerStyle = 'text-30 sm:text-40 font-bold text-center md:text-left';
