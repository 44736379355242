import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function HidePodiumWidget():null {
  const location = useLocation();

  // Determine if the Podium widget should be hidden based on the current pathname
  const isPageToHide: boolean = location.pathname.includes('/') || location.pathname === '/checkout';

  // Function to add Podium widget script to DOM
  const showPodiumWidget = (): void => {
    const podiumWebsiteWidget: HTMLElement | null = document.getElementById('podium-website-widget');

    if (podiumWebsiteWidget) {
      podiumWebsiteWidget.style.display = 'block';
    }
  };

  // Function to remove Podium widget script from DOM
  const hidePodiumWidget = (): void => {
    const podiumWebsiteWidget: HTMLElement | null = document.getElementById('podium-website-widget');

    if (podiumWebsiteWidget) {
      podiumWebsiteWidget.style.display = 'none';
    }
  };

  useEffect(() => {
    if (isPageToHide) {
      hidePodiumWidget();
    } else {
      showPodiumWidget();
    }
  }, [isPageToHide]);

  return null;
}
