import NormalLayout from "layouts/normal";
import { Link } from "react-router-dom";
import notFoundLogo from '../../assets/images/not-found.svg';
import { IoIosArrowForward } from "react-icons/io";

function ErrorPage({ message }: { message: string }) {
  return (
    <NormalLayout>
         <div className="max-w-mxWidth lap:mx-auto w-full mt-90">
         <main className="grid grid-cols-1 sm:grid-cols-2 justify-center items-center p-16 gap-16 lap:gap-64 mt-20 lap:mt-0  w-full">
                <div className="text-center sm:text-left">
               

                        <h1 className="text-4xl lap:text-6xl font-bold  text-black">Ooops ...</h1>
                        
                        <p className="text-lg mb-8 lap:mt-8">
                            {message}
                        </p>
                            
                        <div className="mt-8 lap:mt-32">
                            <Link to="/" className="flex flex-row items-center justify-start gap-2">
                              <span>Home page</span>
                              <IoIosArrowForward className="mt-3" />
                            </Link>
                        </div>
                </div>
                <div className="flex items-center justify-center w-full">
                  <img src={notFoundLogo} className=" w-180 h-140 sm:w-full sm:h-full" alt="404" />
                </div>
               

            
            </main>
         </div>
     
    </NormalLayout>
  );
}

export default ErrorPage;
