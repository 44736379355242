import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import logo from 'assets/images/logo-big-rentals-black-white.png';
import { screenStyles } from '../core/constants/styles';

function HeaderLogo() {
  const navigate = useNavigate();

  const [background, setBackground] = useState('');
  const [showMMenu, setShowMMenu] = useState(false);

  const listenScrollEvent = (e: any) => {
    const fullUrl = window.location.href;
    const pathName = fullUrl.split('#')[1];
    const convertPos = pathName === '/' ? 15 : 50;

    if (window.scrollY > convertPos) {
      setBackground('bg-darkGreen animate-show');
    } else {
      setBackground('bg-tranparent');
    }
  };

  const listenResizeWdith = (e: any) => {
    // if (window.innerWidth >= 1280 && showMMenu) {
    setShowMMenu(false);
    // }
  };

  const toggleMobileMenu = () => {
    if (!showMMenu) {
      setShowMMenu(true);
    } else {
      setShowMMenu(false);
    }
  };

  const onMenuClick = (e: any) => {
    const { key, url } = e;
    // console.log(url);

    if (url) {
      navigate(url);
    }

    // navigate(key);
    switch (key) {
      case '/trailer-rentals':
        navigate('/trailer-rentals');
        break;
      default:
        navigate('/');
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    window.addEventListener('resize', listenResizeWdith);
  }, []);

  const headerStyles = {
    mobileMenu: 'fixed left-0 top-80 grid w-full bg-white p-24 z-[101] font-medium text-16',
    header: `flex justify-between items-center font-medium ${screenStyles.mContain}`,
    mMenuItem: 'text-black py-10 border-b-1 border-softpeach ',
  };

  return (
    <div className="text-white">
      <div className={`${showMMenu ? 'bg-darkGreen' : background} fixed top-0 left-0 w-full z-900 bg-headerGradient`}>
        <div className={`${screenStyles.desktop} my-20 max-w-mxWidth mx-auto `}>
          <div className={`${headerStyles.header} mt-26`}>
            <div className="display-menu-sapce flex items-center text-white text-16">
              <img src={logo} className="App-logo cursor-pointer" onClick={() => navigate('/')} style={{ 'pointerEvents': 'all' }} alt="" />
            </div>
          </div>
        </div>

        <div className={`${screenStyles.mobile} w-screen py-25 px-20`}>
          <div className="flex justify-center w-full text-white">
            <img className="App-logo" src={logo} alt="" onClick={() => navigate('/')} style={{ 'pointerEvents': 'all' }} />
          </div>
        </div>
      </div>
    </div>

  );
}

export default HeaderLogo;
