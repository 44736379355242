export function hideChatWidgetHelper() {
  function getChatWidgetElement () {
    const wrapperElement = document.getElementById('hubspot-messages-iframe-container');
    if (wrapperElement) {
      return wrapperElement;
    }

    return document.querySelector('[data-test-id="chat-widget-iframe"]')?.parentElement;
  }

  // Function to add Chat widget script to DOM
  function showChatWidget(): void {
    getChatWidgetElement()?.style?.setProperty?.('display', 'block', 'important');
  }

  // Function to remove Chat widget script from DOM
  function hideChatWidget(): void {
    getChatWidgetElement()?.style?.setProperty?.('display', 'none', 'important');
  }

  return {
    hideChatWidget,
    showChatWidget,
  };
}
