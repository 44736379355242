import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import HidePodiumWidget from 'components/hide-podium-widget/hide-podium-widget';
import HideChatWidget from 'components/hide-chat-widget/hide-chat-widget';
import Header from './Header';
import Footer from './Footer';
import type { INormalLayoutProps } from './normal-layout-interface';
import HeaderLogo from './header-logo';
import { SettingContext } from 'core/context/SettingContext';
import Backdrop from 'components/Backdrop/Backdrop';

function NormalLayout({
  children,
  hideHeader = false,
  hideFooter = false,
  headerLogo = false,
}: INormalLayoutProps) {
  const filterDate = useSelector((state: any) => state.setting.filterDate);
  const cartItems = useSelector((state: any) => state.setting.cartItems);
  const { activeGradient, setActiveGradient } = useContext(SettingContext);

  useEffect(() => {
    if (filterDate) {
      const settingInfo = JSON.parse(window.localStorage.getItem('Setting') ?? '{}');

      window.localStorage.setItem(
        'Setting',
        JSON.stringify({
          ...settingInfo,
          dateFilter: {
            ...filterDate,
          },
        }),
      );
    }
  }, [filterDate]);

  useEffect(() => {
    const settingInfo = JSON.parse(window.localStorage.getItem('Setting') ?? '{}');

    window.localStorage.setItem(
      'Setting',
      JSON.stringify({
        ...settingInfo,
        cartItems,
      }),
    );
  }, [cartItems]);


  return (
    <div className="flex flex-col min-h-screen font bg-white text-14 ">
      {
       activeGradient ? <Backdrop active={true} /> : <Backdrop active={false} />
      }
      {
        !hideHeader && (headerLogo ? <HeaderLogo /> : <Header />)
      }
      {
        children
      }
      {
        !hideFooter && <Footer />
      }
      <HidePodiumWidget />
      <HideChatWidget />
    </div>
  );
}

export default NormalLayout;
